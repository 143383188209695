import * as React from 'react'
import './header.css'

const Header = () => {
    return(
        <div className="header-wrapper">
            <h1>
                Sean Delaney is a software engineer living in Brooklyn, New York. 
            </h1>
        </div>

        
    )
}
export default Header 