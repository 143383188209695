import * as React from 'react';
import Header from '../Components/header.js';
import './index.css'
import "@fontsource/syne";

const IndexPage = () => {
  return (
    <main>
      <title>Sean Delaney</title>
      <Header />
    </main>
  )
}

export default IndexPage